import { SelectProps } from "antd";
import _ from "lodash";
import { PureComponent, ReactNode } from "react";
import { CommonProps } from "../common/common-props";
import { SingleSelect } from "../single-select/single-select";

export interface IMultiSelectProps extends SelectProps<any> {
  datatextfield?: string;
  datavaluefield?: any;
  onChange?: (value: any) => void;
  defaultText?: string;
  sortBy?: string;
}

interface IMultiSelectState {}

class MultiSelect extends PureComponent<IMultiSelectProps & CommonProps, IMultiSelectState> {
  constructor(props: IMultiSelectProps) {
    super(props);
  }

  getProps = () => {
    const props: any = _.clone(this.props);
    const style: any = _.clone(this.props.style);
    props.mode = "multiple";
    style.display = "inline-block";
    props.style = style;
    if (!props.value) {
      props.value = undefined;
    }
    return props;
  };

  render(): ReactNode {
    return (
      <>
        <SingleSelect {...this.getProps()}></SingleSelect>
      </>
    );
  }
}

const multiSelect = MultiSelect;
export { multiSelect as MultiSelect };
