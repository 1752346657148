import { Timeline as AntdTimeline, TimelineItemProps } from "antd";
import { Guid } from "guid-typescript";
import { CSSProperties, PureComponent, ReactNode } from "react";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import "./timeline.scss";

declare let window: Window & { [key: string]: any };

export interface ITimelineProps {
  className?: string;
  style?: CSSProperties;
  options?: object[];
  datatitlefield?: string;
  datadescriptionfield?: string;
  datacolorfield?: string;
  mode: "left" | "alternate" | "right";
}

interface ITimelineState {
  uniqueKey: Guid;
}

class Timeline extends PureComponent<ITimelineProps, ITimelineState> {
  private memoizedDynamicCssResult = "";

  private readonly isDashboard: boolean;

  private static readonly dashboardDataTitleField?: string = "label";

  private static readonly dashboardDataDescriptionField?: string = "children";

  private static readonly dashboardDataColorField?: string = "colr";

  private dataTitleField?: string;

  private dataDescriptionField?: string;

  private dataColorField?: string;

  private static readonly dashboardData = [
    {
      label: "1.",
      color: "green",
      children: "Create a services site 2015-09-01"
    },
    {
      label: "2.",
      color: "red",
      children: "Solve initial network problems 2015-09-01"
    }
  ] as TimelineItemProps[];

  constructor(props: ITimelineProps) {
    super(props);
    this.state = { uniqueKey: Guid.create() };

    this.isDashboard = window.kuika?.dashboardState === 1;
    this.initializeFieldKeys();
  }

  private initializeFieldKeys = (): void => {
    if (this.isDashboard) {
      this.dataTitleField = Timeline.dashboardDataTitleField;
      this.dataDescriptionField = Timeline.dashboardDataDescriptionField;
      this.dataColorField = Timeline.dashboardDataColorField;
    } else {
      this.dataTitleField = this.props.datatitlefield;
      this.dataDescriptionField = this.props.datadescriptionfield;
      this.dataColorField = this.props.datacolorfield;
    }
  };

  componentDidMount() {
    this.setDynamicStyle();
  }

  getDynamicCss = (): string => {
    const className: string = this.getClassName();
    if (!className || className.length === 0) {
      return "";
    }
    let result = "";
    const { paddingTop, fontSize, borderStyle, borderBottomWidth, borderLeftWidth, borderRightWidth, borderTopWidth } =
      this.props.style;
    if (!paddingTop) {
      result += `.${className.trim()} {
          padding-top: 8px !important;
        }`;
    }
    const antTimeLineItemLabel = document.querySelector(`.${className.trim()} .ant-timeline-item-label`);
    if (antTimeLineItemLabel) {
      const width = antTimeLineItemLabel.clientWidth;
      result += `.${className.trim()} .ant-timeline-item-content {
          width: ${width - 16}px !important;
        }`;
    }
    if (fontSize) {
      result += `.${className.trim()} .ant-timeline-item-label {
          font-size: ${fontSize} !important;
        }`;

      result += `.${className.trim()} .ant-timeline-item-content {
          font-size: ${fontSize} !important;
        }`;
    }
    if (
      !borderStyle &&
      (borderBottomWidth || borderTopWidth || borderBottomWidth || borderLeftWidth || borderRightWidth)
    ) {
      result += `.${className.trim()} {
          border-style: solid;
        }`;
    }
    return result;
  };

  setDynamicStyle = () => {
    const uniquekey = this.state.uniqueKey?.toString();
    if (!uniquekey) {
      return;
    }
    const isDesignTime = window.kuika?.isDesignTime;
    if (this.memoizedDynamicCssResult !== "" && !isDesignTime) {
      return this.memoizedDynamicCssResult;
    }
    const dynamic_style = document.getElementById("dynamic_style");
    if (dynamic_style && dynamic_style.innerHTML?.indexOf(uniquekey) === -1) {
      const generatedCss = this.getDynamicCss();
      dynamic_style.innerHTML = `${dynamic_style.innerHTML} 
        ${generatedCss}`;
      this.memoizedDynamicCssResult = generatedCss;
    }
  };

  private getClassName = (): string => {
    let result = "";

    if (this.props.className) {
      result = `${this.props.className} `;
    }

    if (!this.state.uniqueKey) {
      return result;
    }

    result = `${result}Timeline_${this.state.uniqueKey.toString()}`;
    return result;
  };

  private getItems = (): TimelineItemProps[] => {
    const source = this.isDashboard ? Timeline.dashboardData : this.props.options ?? [];
    const items = source.map((item) => {
      return {
        label: item[this.dataTitleField],
        children: item[this.dataDescriptionField],
        color: item[this.dataColorField]
      };
    });
    return items;
  };

  private getStyle = (): CSSProperties => {
    const style: CSSProperties = { ...this.props.style };
    return style;
  };

  render(): ReactNode {
    return (
      <AntdTimeline
        mode={this.props.mode}
        items={this.getItems()}
        className={this.getClassName()}
        style={this.getStyle()}
      />
    );
  }
}

const timeline = withCommonEvents(Timeline);
export { timeline as Timeline };
