import { Input } from "antd";
import _ from "lodash";
import React, { PureComponent } from "react";
import { NumberFormatProps } from "react-number-format";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common-props";

declare let window: any;
interface BarcodeInputProps extends NumberFormatProps {
  onChange: any;
}

interface BarcodeInputState {}

class BarcodeInput extends PureComponent<BarcodeInputProps & CommonProps, BarcodeInputState> {
  constructor(props: BarcodeInputProps) {
    super(props);
    this.state = {};
  }

  defaultAllowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown", "Enter", "Delete"];

  getProps = () => {
    const props: any = _.clone(this.props);
    let style: any = _.clone(this.props.style);
    if (!style) {
      style = {
        width: "100%"
      };
      props.style = style;
    } else if (!style.width) {
      style.width = "100%";
      props.style = style;
    }
    if (props.onChange) {
      delete props.onChange;
    }
    if (props.decimalSeparator) {
      delete props.decimalSeparator;
    }
    if (props.value === null) {
      props.value = "";
    }
    return props;
  };

  onChange = (value: number | any) => {
    if (this.props.onChange) {
      const newValue = value.value != undefined ? value.value : value;
      this.props.onChange(newValue);
    }
  };

  onBlur = (e: any) => {
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  };

  render(): React.ReactNode {
    return (
      <Input
        {...this.getProps()}
        onBlur={this.onBlur}
        onChange={this.onChange}
        onKeyDown={(e) => {
          if (!this.defaultAllowedKeys.includes(e.key)) {
            e.preventDefault();
          }
        }}
        onPaste={(e) => {
          e.preventDefault();
        }}
        allowClear
      />
    );
  }
}

const barcodeInput = withCommonEvents(BarcodeInput);
export { barcodeInput as BarcodeInput };
