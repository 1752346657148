import { Select } from "antd";
import _ from "lodash";
import { PureComponent, ReactNode } from "react";
import ReactDOM from "react-dom";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { ReactSystemFunctions } from "../../../shared/utilty/react-system-functions";
import { CommonProps } from "../common-props";

const { Option } = Select;

declare let window: any;

interface LanguageSelectorProps {
  value: string;
  languages: ILang[];
  style: any;
  onChange: (value: string) => void;
}

export interface ILang {
  Id: number;
  Name: string;
  ShortName: string;
  IsDefault: boolean;
}

interface LanguageSelectorState {}

class LanguageSelector extends PureComponent<LanguageSelectorProps & CommonProps, LanguageSelectorState> {
  constructor(props: LanguageSelectorProps) {
    super(props);
    this.state = {};
  }

  handleComponentStyling = () => {
    if (this.props.style?.height) {
      this.handleHeight();
    }
    if (this.props.style?.color) {
      this.handleColor();
    }
    if (this.props.style?.fontSize) {
      this.handleFontSize();
    }
    if (this.props.style?.padding) {
      this.handlePadding();
    }
    if (this.props.style?.paddingTop) {
      this.handlePaddingTop();
    }
    if (this.props.style?.paddingRight) {
      this.handlePaddingRight();
    }
    if (this.props.style?.paddingBottom) {
      this.handlePaddingBottom();
    }
    if (this.props.style?.paddingLeft) {
      this.handlePaddingLeft();
    }
    if (this.props.style) {
      this.handleBorderRadius();
    }
    if (this.props.style) {
      this.handleBorder();
    }
    if (this.props.style?.backgroundColor) {
      this.handleFillColor();
    }
  };

  handleHeight = () => {
    const node = ReactDOM.findDOMNode(this) as Element;
    const item = node.querySelector(".ant-select-selector") as HTMLElement;
    item.style.height = this.props.style!.height as any;
    item.style.minHeight = this.props.style!.minHeight as any;
    item.style.maxHeight = this.props.style!.maxHeight as any;
    item.style.alignItems = "center";
  };

  handleFillColor = () => {
    const node = ReactDOM.findDOMNode(this) as Element;
    const item = node.querySelector(".ant-select-selector") as HTMLElement;
    item.style.backgroundColor = this.props.style!.backgroundColor as any;
  };

  handlePadding = () => {
    const node = ReactDOM.findDOMNode(this) as Element;
    const item = node.querySelector(".ant-select-selector") as HTMLElement;
    if (this.props.style?.padding) {
      item.style.padding = `${this.props.style!.padding?.toString()}px` as any;
      item.style.height = this.props.style!.height ?? this.props.style!.minHeight ?? "unset";
    }
    const arrowItem = node.querySelector(".ant-select-arrow") as HTMLElement;
    if (this.props.style?.padding) {
      arrowItem.style.paddingRight = `${this.props.style!.padding?.toString()}px` as any;
    }
  };

  handlePaddingTop = () => {
    const node = ReactDOM.findDOMNode(this) as Element;
    const item = node.querySelector(".ant-select-selector") as HTMLElement;
    item.style.height = this.props.style!.height ?? this.props.style!.minHeight ?? "unset";
    if (this.props.style?.paddingTop && item && item.style) {
      item.style.paddingTop = `${this.props.style!.paddingTop?.toString()}px` as any;
    }
    if (this.props.style && this.props.style?.paddingTop && this.props.style?.paddingTop === 8) {
      item.style.paddingTop = "4px";
    }
  };

  handlePaddingRight = () => {
    const node = ReactDOM.findDOMNode(this) as Element;
    const item = node.querySelector(".ant-select-selector") as HTMLElement;
    item.style.height = this.props.style!.height ?? this.props.style!.minHeight ?? "unset";
    if (this.props.style?.paddingRight) {
      item.style.paddingRight = `${this.props.style!.paddingRight?.toString()}px` as any;
    }
    const arrowItem = node.querySelector(".ant-select-arrow") as HTMLElement;
    if (this.props.style?.paddingRight && arrowItem && arrowItem.style) {
      arrowItem.style.paddingRight = `${this.props.style!.paddingRight?.toString()}px` as any;
    }
  };

  handlePaddingBottom = () => {
    const node = ReactDOM.findDOMNode(this) as Element;
    const item = node.querySelector(".ant-select-selector") as HTMLElement;
    item.style.height = this.props.style!.height ?? this.props.style!.minHeight ?? "unset";
    if (this.props.style?.paddingBottom) {
      item.style.paddingBottom = `${this.props.style!.paddingBottom?.toString()}px` as any;
    }
    if (this.props.style && this.props.style?.paddingBottom && this.props.style?.paddingBottom === 8) {
      item.style.paddingBottom = "4px";
    }
  };

  handlePaddingLeft = () => {
    const node = ReactDOM.findDOMNode(this) as Element;
    const item = node.querySelector(".ant-select-selector") as HTMLElement;
    item.style.height = this.props.style!.height ?? this.props.style!.minHeight ?? "unset";
    if (this.props.style?.paddingLeft && item && item.style) {
      item.style.paddingLeft = `${this.props.style!.paddingLeft?.toString()}px` as any;
    }
  };

  handleBorderRadius = () => {
    const { style } = this.props;
    const node = ReactDOM.findDOMNode(this) as Element;
    const item = node.querySelector(".ant-select-selector") as HTMLElement;
    if (style?.borderRadius) {
      item.style.borderRadius = `${style.borderRadius.toString()}px` as any;
    }
    if (style?.borderTopLeftRadius) {
      item.style.borderTopLeftRadius = `${style.borderTopLeftRadius.toString()}px` as any;
    }
    if (style?.borderTopRightRadius) {
      item.style.borderTopRightRadius = `${style.borderTopRightRadius.toString()}px` as any;
    }
    if (style?.borderBottomLeftRadius) {
      item.style.borderBottomLeftRadius = `${style.borderBottomLeftRadius.toString()}px` as any;
    }
    if (style?.borderBottomRightRadius) {
      item.style.borderBottomRightRadius = `${style.borderBottomRightRadius.toString()}px` as any;
    }
  };

  handleBorder = () => {
    const { style } = this.props;
    const node = ReactDOM.findDOMNode(this) as Element;
    const item = node.querySelector(".ant-select-selector") as HTMLElement;
    if (style?.border) {
      item.style.border = style.border as any;
    }
    if (style?.borderRadius) {
      item.style.borderRadius = style.borderRadius as any;
    }
    if (style?.borderWidth) {
      item.style.borderWidth = style.borderWidth as any;
    }
    if (style?.borderColor) {
      item.style.borderColor = style.borderColor as any;
    }
    if (style?.borderStyle) {
      item.style.borderStyle = style.borderStyle as any;
    }
    if (style?.borderTopWidth != undefined) {
      item.style.borderTopWidth = `${style.borderTopWidth.toString()}px` as any;
    }
    if (style?.borderRightWidth != undefined) {
      item.style.borderRightWidth = `${style.borderRightWidth.toString()}px` as any;
    }
    if (style?.borderBottomWidth != undefined) {
      item.style.borderBottomWidth = `${style.borderBottomWidth.toString()}px` as any;
    }
    if (style?.borderLeftWidth != undefined) {
      item.style.borderLeftWidth = `${style.borderLeftWidth.toString()}px` as any;
    }
  };

  handleColor = () => {
    const node = ReactDOM.findDOMNode(this) as Element;
    const placeholderItem: HTMLElement = node.getElementsByClassName(
      "ant-select-selection-placeholder"
    )[0] as HTMLElement;
    if (placeholderItem) {
      placeholderItem.style.color = this.props.style?.color as any;
    }
    const selectionItem: HTMLElement = node.getElementsByClassName("ant-select-selection-item")[0] as HTMLElement;
    if (selectionItem) {
      selectionItem.style.color = this.props.style?.color as any;
    }
  };

  handleFontSize = () => {
    const node = ReactDOM.findDOMNode(this) as Element;
    const item = node.firstChild as HTMLElement;
    item.style.fontSize = this.props.style!.fontSize as any;
    item.style.alignItems = "center";
  };

  componentDidMount(): void {
    this.handleComponentStyling();
  }

  componentDidUpdate(): void {
    this.handleComponentStyling();
  }

  getProps = () => {
    const isDesignTime = window.kuika?.isDesignTime;
    const props: any = {};

    let style: any | undefined = _.clone(this.props.style);
    if (props.options) {
      delete props.options;
    }
    if (!style) {
      style = {
        width: "100%"
      };
    } else if (!style.width) {
      style.width = "100%";
    }
    if (style) {
      style.border = "none";
      delete style.padding;
      delete style.paddingTop;
      delete style.paddingRight;
      delete style.paddingBottom;
      delete style.paddingLeft;
      delete style.borderLeftWidth;
      delete style.borderBottomWidth;
      delete style.borderRightWidth;
      delete style.borderTopWidth;
      delete style.borderTopLeftRadius;
      delete style.borderTopRightRadius;
      delete style.borderBottomLeftRadius;
      delete style.borderBottomRightRadius;

      if (style.display == "block") {
        style.display = "flex";
      } else if (style.display == "inline") {
        style.display = "inline-flex";
      }
    }
    props.style = style;
    if (style && style.height) {
      delete style.height;
      props.style = style;
    }
    if (props.value == "") {
      delete props.value;
    }
    if (props.value) {
      delete props.value;
    }
    if (isDesignTime) {
      props.getPopupContainer = (triggerNode) => triggerNode.parentNode;
    }
    return props;
  };

  handleChange = (value: any, option: any) => {
    if (this.props.onChange) {
      this.props.onChange(value);
      ReactSystemFunctions.setLanguage(this, value);
    }
  };

  render(): ReactNode {
    return (
      <>
        <Select
          placeholder="Select a Language ..."
          className={this.props.className}
          value={this.props.value}
          onChange={this.handleChange}
          {...this.getProps()}
        >
          {this.props.languages &&
            ReactSystemFunctions.getAvailableLanguages(this.props.languages)
              ?.sort((a, b) => a?.name?.localeCompare(b.name))
              ?.map((s) => {
                return (
                  <Option key={s?.name} value={s?.name}>
                    {s.shortName}
                  </Option>
                );
              })}
        </Select>
      </>
    );
  }
}

const languageSelector = withCommonEvents(LanguageSelector);
export { languageSelector as LanguageSelector };
