import { Axis, Chart, Geom, Legend, View } from "bizcharts";
import _ from "lodash";
import React, { ReactNode } from "react";
import withCommonEvents from "../../../../shared/hoc/with-common-events";
import { CommonProps } from "../../common-props";

export interface MixedChartProps {
  xAxis: any[];
  gap: number;
  style?: React.CSSProperties;
  children?: any;
  options?: any[];
  dataxaxisesfield?: string;
}

export interface MixedChartState {}

declare let window: any;

const yGap = 4;

class MixedChart extends React.PureComponent<MixedChartProps & CommonProps, MixedChartState> {
  private static readonly xAxisDatas = [0, 3, 6, 9, 12, 18, 24, 30, 36];

  private static readonly linesDashboardData = [
    {
      label: "line1",
      dashSpacing: 5,
      dashLength: 10,
      lineData: [32.3, 38.5, 41.3, 43.1, 44.3, 45.6, 46.4, 46.9, 47.1]
    },
    {
      label: "line2",
      dashSpacing: 3,
      dashLength: 1,
      lineData: [33.2, 39.3, 42.2, 44.0, 45.2, 46.5, 47.3, 47.8, 48.0]
    },
    {
      label: "line3",
      dashSpacing: 10,
      dashLength: 20,
      lineData: [34.0, 40.2, 43.1, 44.9, 46.1, 47.4, 48.3, 48.8, 49.0]
    }
  ];

  private static readonly bubblesDashboardData = [
    {
      xAxis: 3,
      value: 40
    },
    {
      xAxis: 12,
      value: 46
    },
    {
      xAxis: 30,
      value: 48
    }
  ];

  constructor(props: MixedChartProps & CommonProps) {
    super(props);
    this.state = {};
  }

  lowercaseKeys(obj) {
    return Object.keys(obj).reduce((accumulator, key) => {
      accumulator[key.toLowerCase()] = obj[key];
      return accumulator;
    }, {});
  }

  convertLineOptionsToData = (children) => {
    if (window.kuika?.isDesignTime) {
      return MixedChart.linesDashboardData.flatMap((line) => {
        return line.lineData.map((value, i) => ({
          label: line.label,
          value,
          xAxis: MixedChart.xAxisDatas[i],
          dash: [line.dashLength ?? 0, line.dashSpacing ?? 0],
          lineWidth: 2
        }));
      });
    }
    let lines = [];
    if (children && children.length > 0) {
      lines = children.filter((c) => c.props.type === "line");
    } else {
      lines = children?.props?.type === "line" ? [children] : [];
    }
    const items = lines?.flatMap((item, i) => {
      return item.props.options
        ?.sort((a, b) => a[item.props.dataxaxisfield] - b[item.props.dataxaxisfield])
        ?.map((options, i) => {
          const lowerCaseOptions = this.lowercaseKeys(options);
          return {
            label: item.props.label ?? `Line ${i + 1}`,
            value: parseFloat(lowerCaseOptions[item.props.dataxaxisvaluefield?.toLowerCase()]),
            xAxis: parseFloat(lowerCaseOptions[item.props.dataxaxisfield?.toLowerCase()]),
            tooltipTitle: lowerCaseOptions[item.props.dataxaxistooltipfield?.toLowerCase()],
            tooltipValue: lowerCaseOptions[item.props.datavaluetooltipfield?.toLowerCase()],
            dash: [item.props.dashLength ?? 10, item.props.dashSpacing ?? 5],
            lineWidth: item.props.lineWidth ?? 2
          };
        });
    });
    return items;
  };

  convertPointOptionsToData = (children, linesMinXAxis) => {
    if (window.kuika?.isDesignTime) {
      return MixedChart.bubblesDashboardData;
    }
    let points = [];
    if (children && children.length > 0) {
      points = children.filter((c) => c.props.type === "point");
    } else {
      points = children?.props?.type === "point" ? [children] : [];
    }
    const items = points?.flatMap((item, i) => {
      return item.props.options
        ?.sort((a, b) => a[item.props.dataxaxisfield] - b[item.props.dataxaxisfield])
        ?.map((options, i) => {
          const lowerCaseOptions = this.lowercaseKeys(options);
          const value = parseFloat(lowerCaseOptions[item.props.datavaluefield?.toLowerCase()]);
          const xAxis = parseFloat(lowerCaseOptions[item.props.dataxaxisfield?.toLowerCase()]);
          const sanitizedValue = Math.max(value, linesMinXAxis);
          const sanitizedXAxis = Math.max(xAxis, linesMinXAxis);
          return {
            xAxis: sanitizedXAxis,
            value: sanitizedValue,
            tooltipTitle: lowerCaseOptions[item.props.dataxaxistooltipfield?.toLowerCase()],
            tooltipValue: lowerCaseOptions[item.props.datavaluetooltipfield?.toLowerCase()]
          };
        });
    });
    return items;
  };

  convertOptionsToData = () => {
    if (window.kuika?.isDesignTime) {
      return MixedChart.xAxisDatas;
    }
    const items = this.props.options?.map((option, i) => {
      const lowerCaseOption = this.lowercaseKeys(option);
      return parseFloat(lowerCaseOption[this.props.dataxaxisesfield?.toLowerCase()]);
    });
    items?.sort((a, b) => a - b);
    return items;
  };

  getLines = () => {
    if (window.kuika?.isDesignTime) {
      return MixedChart.linesDashboardData;
    }
    let lines = [];
    const children = this.getProps().children;
    if (children && children.length > 0) {
      lines = children.filter((c) => c.props.type === "line");
    } else {
      lines = children?.props?.type === "line" ? [children] : [];
    }
    return lines;
  };

  getPoints = () => {
    if (window.kuika?.isDesignTime) {
      return MixedChart.bubblesDashboardData;
    }
    let points = [];
    const children = this.getProps().children;
    if (children && children.length > 0) {
      points = children.filter((c) => c.props.type === "point");
    } else {
      points = children?.props?.type === "point" ? [children] : [];
    }
    return points;
  };

  getProps = () => {
    const props: any = _.clone(this.props);
    const style: any = _.clone(this.props.style);
    if (style.width) {
      props.width = style.width;
      delete style.width;
    }
    if (style.height) {
      props.height = style.height;
      delete style.height;
    } else {
      props.height = 400;
    }
    props.padding = [
      style.paddingTop ?? 16,
      style.paddingRight ?? 16,
      style.paddingBottom ?? 16,
      style.paddingLeft ?? 16
    ];
    const children = _.clone(this.props.children);
    let modifiedChildren = {};
    if (children && children.length > 0) {
      modifiedChildren = children.map((child, index) => {
        const childProps = _.clone(child.props);

        if ((!childProps.label || childProps.label === "") && child.props.type === "line") {
          childProps.label = childProps.type + (index + 1);
        }
        if (!childProps.color || childProps.color === "") {
          childProps.color = "rgba(36, 110, 255, 1)";
        }
        return {
          ...child,
          props: childProps
        };
      });
    } else {
      const childProps = _.clone(children.props);
      if ((!childProps.label || childProps.label === "") && children.props.type === "line") {
        childProps.label = childProps.type + 1;
      }
      if (!childProps.color || childProps.color === "") {
        childProps.color = "rgba(36, 110, 255, 1)";
      }
      modifiedChildren = {
        ...children,
        props: childProps
      };
    }

    props.children = modifiedChildren;
    props.style = style;
    return props;
  };

  render(): ReactNode {
    const isDesignTime = window.kuika?.isDesignTime;
    const xAxisDatas = this.convertOptionsToData();
    const children = this.getProps().children;
    const lineMappedDatas = this.convertLineOptionsToData(children);
    const minYvalue = Math.min(...lineMappedDatas.map((item) => item?.value)) - yGap;
    const maxYvalue = Math.max(...lineMappedDatas.map((item) => item?.value)) + yGap;
    const linesMinXAxis = Math.min(...lineMappedDatas.map((item) => item?.xAxis));
    const linesMaxXAxis = Math.max(...lineMappedDatas.map((item) => item?.xAxis));
    const pointsData = this.convertPointOptionsToData(children, linesMinXAxis);
    let minXAxis = null;
    let maxXAxis = null;
    if (xAxisDatas && xAxisDatas.length > 0) {
      minXAxis = Math.min(...xAxisDatas);
      maxXAxis = Math.max(...xAxisDatas);
    }
    if (minXAxis && linesMinXAxis && linesMinXAxis < minXAxis) {
      xAxisDatas?.unshift(linesMinXAxis);
    }
    if (maxXAxis && linesMaxXAxis && linesMaxXAxis > maxXAxis) {
      xAxisDatas?.push(linesMaxXAxis);
    }

    const commonScale = {
      xAxis: {
        ticks: xAxisDatas
      },
      x: {
        ticks: xAxisDatas
      },
      y: {
        min: minYvalue,
        max: maxYvalue,
        tickInterval: yGap
      },
      value: {
        min: minYvalue,
        max: maxYvalue,
        tickInterval: yGap
      }
    };

    const lines = this.getLines();
    const points = this.getPoints();

    const views = [];
    if ((lines && lines.length) > 0) {
      views.push(
        <View data={lineMappedDatas} scale={commonScale} key="line">
          <Geom
            type="line"
            position="xAxis*value"
            size={4}
            shape="smooth"
            style={{
              fields: ["xAxis", "value"],
              callback: (xVal, yVal) => {
                const style = { lineDash: [5, 10], lineWidth: 2 };
                style.lineDash = lineMappedDatas.find((item) => item.xAxis === xVal && item.value === yVal)?.dash;
                style.lineWidth = lineMappedDatas.find((item) => item.xAxis === xVal && item.value === yVal)?.lineWidth;
                return style;
              }
            }}
            color={[
              "label",
              (label) => {
                const children = this.getProps().children;
                let child;
                if (children.length > 0) {
                  child = children.find((item) => item.props.label === label);
                } else {
                  child = children.props.label === label ? children : {};
                }
                if (child) {
                  return isDesignTime ? "rgba(36, 110, 255, 1)" : child.props?.color ?? "rgba(36, 110, 255, 1)";
                }
                return "rgba(36, 110, 255, 1)";
              }
            ]}
            tooltip={
              isDesignTime
                ? false
                : [
                    "xAxis*value",
                    (xAxis, value) => {
                      const item = lineMappedDatas.find((item) => item.xAxis === xAxis && item.value === value);
                      return {
                        name: item?.tooltipTitle ?? xAxis,
                        value: item?.tooltipValue ?? value
                      };
                    }
                  ]
            }
          />
        </View>
      );
    }

    if ((points && points.length) > 0) {
      views.push(
        <View data={pointsData} scale={commonScale} key="point">
          <Geom
            type="point"
            position="xAxis*value"
            size={5}
            shape="circle"
            style={{
              fields: ["xAxis", "value"],
              callback: (xVal, yVal) => {
                const style = { lineWidth: 1 };
                return style;
              }
            }}
            color={[
              "x",
              (id) => {
                const children = this.getProps().children;
                let child;
                if (children.length > 0) {
                  child = children.find((item) => item.props.type === "point");
                } else {
                  child = children.props.type === "point" ? children : {};
                }
                if (child) {
                  return isDesignTime ? "rgba(36, 110, 255, 1)" : child.props?.color ?? "rgba(36, 110, 255, 1)";
                }
                return "rgba(36, 110, 255, 1)";
              }
            ]}
            tooltip={
              isDesignTime
                ? false
                : [
                    "xAxis*value",
                    (xAxis, value) => {
                      const item = pointsData.find((item) => item.xAxis === xAxis && item.value === value);
                      return {
                        name: item?.tooltipTitle ?? xAxis,
                        value: item?.tooltipValue ?? value
                      };
                    }
                  ]
            }
          />
        </View>
      );
    }

    return (
      <Chart
        {...this.getProps()}
        data={pointsData}
        scale={commonScale}
        autoFit
        onAxisLabelClick={console.log}
        style={{ ...this.props.style }}
      >
        <Legend visible={false} />
        <Axis name="xAxis" grid={{ line: { style: { lineWidth: 0.3 } } }} />
        <Axis
          name="value"
          label={{
            formatter: (val) => `${val}`
          }}
        />
        {views}
      </Chart>
    );
  }
}
const mixedChartStatic = withCommonEvents(MixedChart);
export { mixedChartStatic as MixedChart };
