import _ from "lodash";
import { CSSProperties, PureComponent, ReactNode } from "react";
import ReactDOM from "react-dom";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common/common-props";

declare let window: any;

interface BoxProps {
  style?: CSSProperties;
}

interface BoxState {}

class Box extends PureComponent<BoxProps & CommonProps, BoxState> {
  constructor(props: BoxProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const node: any = ReactDOM.findDOMNode(this);
    if (node && node.style) {
      const height = node.clientHeight - 6;
      node.style.height = `${height}px`;
      node.style.width = "98%";
      node.style.position = "relative";
    }
  }

  getProps = () => {
    const props: any = _.clone(this.props);
    const style: any = props.style || {};
    const isDesignTime = window.kuika && window.kuika.isDesignTime;
    if (isDesignTime && !style.borderColor) {
      style.border = "1px dashed rgb(186 210 255)";
    }
    props.style = style;
    return props;
  };

  render(): ReactNode {
    return <div {...this.getProps()}>{this.props.children}</div>;
  }
}

const box = withCommonEvents(Box);
export { box as Box };
