import _ from "lodash";
import { PickerSharedProps } from "rc-picker/lib/Picker";
import { PureComponent, ReactNode } from "react";
import "react-pivottable/pivottable.css";
import PivotTableUI from "react-pivottable/PivotTableUI";
import createPlotlyRenderers from "react-pivottable/PlotlyRenderers";
import TableRenderers from "react-pivottable/TableRenderers";
import Plot from "react-plotly.js";
import { CSSProperties } from "styled-components";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common-props";

declare let window: any;
interface IPivotTableProps extends PickerSharedProps<any> {
  dataSource?: any[];
  onConfigChange?: () => void;
  style?: CSSProperties;
}

interface IPivotTableState {
  pivotState: any;
}

const PlotlyRenderers = createPlotlyRenderers(Plot);

class PivotTable extends PureComponent<IPivotTableProps & CommonProps, IPivotTableState> {
  constructor(props: IPivotTableProps) {
    super(props);
    this.state = {
      pivotState: {
        data:
          this.props.dataSource && this.props.dataSource.length > 0
            ? this.convertToArrayFormat(this.props.dataSource)
            : [[]],
        unusedOrientationCutoff: Infinity
      }
    };
  }

  convertToArrayFormat = (data: any[]): any[][] => {
    const fields = Object.keys(data[0]);
    const values = data.map((obj) => Object.values(obj));
    return [fields, ...values];
  };

  componentDidMount = () => {};

  getProps = () => {
    const props: any = _.clone(this.props);
    const style: any = props.style || {};
    props.style = style;
    return props;
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.dataSource !== this.props.dataSource) {
      this.setState({
        pivotState: {
          ...this.state.pivotState,
          data:
            this.props.dataSource && this.props.dataSource.length > 0
              ? this.convertToArrayFormat(this.props.dataSource)
              : [[]],
          unusedOrientationCutoff: Infinity
        }
      });
    }
  }

  public getTableConfigJson = () => {
    const json = {
      pivotState: {
        data: this.state.pivotState.data,
        cols: this.state.pivotState.cols,
        rows: this.state.pivotState.rows,
        vals: this.state.pivotState.vals,
        aggregatorName: this.state.pivotState.aggregatorName,
        sorters: this.state.pivotState.sorters,
        valueFilter: this.state.pivotState.valueFilter,
        rowOrder: this.state.pivotState.rowOrder,
        colOrder: this.state.pivotState.colOrder,
        derivedAttributes: this.state.pivotState.derivedAttributes,
        tableOptions: this.state.pivotState.tableOptions,
        rendererName: this.state.pivotState.rendererName,
        // renderers: this.state.pivotState.renderers,
        hiddenAttributes: this.state.pivotState.hiddenAttributes,
        hiddenFromAggregators: this.state.pivotState.hiddenFromAggregators,
        hiddenFromDragDrop: this.state.pivotState.hiddenFromDragDrop,
        menuLimit: this.state.pivotState.menuLimit
      }
    };
    return JSON.stringify(json);
  };

  public setTableConfigJson = (json: string) => {
    if (!json || json === "") return;
    const obj = JSON.parse(json);
    this.setState({
      pivotState: {
        ...this.state.pivotState,
        ...obj.pivotState,
        unusedOrientationCutoff: Infinity
      }
    });
  };

  public resetTableConfigs = () => {
    this.setState({
      pivotState: {
        data: [[]]
      } as any
    });
  };

  render(): ReactNode {
    const isDesignTime = window.kuika?.isDesignTime;

    return (
      <div>
        <div className="row">
          <PivotTableUI
            {...this.state.pivotState}
            onChange={(s) => {
              this.setState({ pivotState: s });
              this.props.onConfigChange && this.props.onConfigChange();
            }}
            renderers={{ ...TableRenderers, ...PlotlyRenderers }}
          />
        </div>
      </div>
    );
  }
}
const pivotTable = withCommonEvents(PivotTable);
export { pivotTable as PivotTable };
