import _ from "lodash";
import React, { PureComponent, ReactNode } from "react";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common-props";
import { DashboardState } from "../kuika-cl-model-runtimes";

declare let window: any;

interface LayoutRightProps {
  style?: React.CSSProperties;
}

interface LayoutRightState {}

class LayoutRight extends PureComponent<LayoutRightProps & CommonProps, LayoutRightState> {
  constructor(props: LayoutRightProps) {
    super(props);
    this.state = {};
  }

  getStyleProp = () => {
    let style: any = {};

    if (this.props.style) {
      style = _.clone(this.props.style);
    }

    delete style.width;
    if (window?.kuika?.dashboardState === DashboardState.design) {
      style.minWidth = "100px";
      style.height = "100%";
    }
    return style;
  };

  getProps = () => {
    const props: any = _.clone(this.props);
    if (props.style) {
      delete props.style;
    }
    return props;
  };

  render(): ReactNode {
    return (
      <>
        <div {...this.getProps()} style={{ ...this.getStyleProp() }}>
          {this.props.children}
        </div>
      </>
    );
  }
}

const layoutRight = withCommonEvents(LayoutRight);
export { layoutRight as LayoutRight };
