import { Badge as AntdBadge } from "antd";
import { BadgeProps as AntdBadgeProps } from "antd/lib/badge";
import _ from "lodash";
import React, { PureComponent, ReactNode } from "react";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common-props";

interface BadgeProps extends AntdBadgeProps {
  value: { count: string };
}

interface BadgeState {}

class Badge extends PureComponent<BadgeProps & CommonProps, BadgeState> {
  constructor(props: BadgeProps) {
    super(props);
    this.state = {};
  }

  getClassName(style: React.CSSProperties) {
    if (style.textDecoration === "underline") {
      return "kuika-badge-component__text-decoration-underline";
    }
    if (style.textDecoration === "line-through") {
      return "kuika-badge-component__text-decoration-line-through";
    }
  }

  getProps = () => {
    const props: any = _.clone(this.props);
    const className = this.getClassName(props.style);
    if (className) {
      props.className = className;
    }
    if (props.value) {
      props.count = props.value.count !== undefined ? props.value.count : props.value;
    }

    if (props.style?.textAlign) {
      delete props.style.textAlign;
    }
    return props;
  };

  render(): ReactNode {
    return !this.props.children ? (
      <div className="kuika-badge-component__right-top-align">
        <AntdBadge {...this.getProps()}>{this.props.children}</AntdBadge>
      </div>
    ) : (
      <AntdBadge {...this.getProps()} style={{ textAlign: "center" }}>
        {this.props.children}
      </AntdBadge>
    );
  }
}

const badge = withCommonEvents(Badge);
export { badge as Badge };
