import { Slider as AntdSlider } from "antd";
import { SliderBaseProps } from "antd/lib/slider";
import _ from "lodash";
import { PureComponent, ReactNode } from "react";
import { findDOMNode } from "react-dom";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common-props";

declare let window: any;

interface SliderRangeProps extends SliderBaseProps {
  style: any;
  sliderColor: string;
  handleColor: string;
  railColor: string;
  onChange: any;
  id?: string;
  startValue: number;
  endValue: number;
  value: RangeSliderValue;
}

interface KSliderState {
  startValue: number;
  endValue: number;
}

interface RangeSliderValue {
  startValue: number;
  endValue: number;
}

class RangeSlider extends PureComponent<SliderRangeProps & CommonProps, KSliderState> {
  constructor(props: SliderRangeProps) {
    super(props);
    this.state = {
      startValue: props.value?.startValue,
      endValue: props.value?.endValue
    };
  }

  static getDerivedStateFromProps(nextProps: SliderRangeProps & CommonProps, prevState: KSliderState) {
    if (window.kuika?.dashboardState === 1) {
      // Designer
      const max = nextProps.max ? nextProps.max : 100;
      const startValue = nextProps.startValue ? nextProps.startValue : max / 3;
      const endValue = nextProps.endValue ? nextProps.endValue : max / 1.5;
      return { startValue, endValue } as KSliderState;
    }
    const result = {} as KSliderState;
    result.startValue = nextProps.value?.startValue;
    result.endValue = nextProps.value?.endValue;
    return result;
  }

  componentDidMount() {
    const node = findDOMNode(this) as Element;
    if (this.props && this.props.sliderColor) {
      const sliderTract1: HTMLElement = node.getElementsByClassName("ant-slider-track")[0] as HTMLElement;
      sliderTract1.style.backgroundColor = this.props.sliderColor;
    }
    if (this.props && this.props.railColor) {
      const sliderTract: HTMLElement = node.getElementsByClassName("ant-slider-rail")[0] as HTMLElement;
      sliderTract.style.backgroundColor = this.props.railColor;
    }
    if (this.props && this.props.handleColor) {
      const sliderTract11: HTMLElement = node.getElementsByClassName("ant-slider-handle")[0] as HTMLElement;
      sliderTract11.style.boxShadow = `0 0 0 2px ${this.props.handleColor}`;
    }
    if (this.props && this.props.handleColor) {
      const sliderTract22: HTMLElement = node.getElementsByClassName("ant-slider-handle")[1] as HTMLElement;
      sliderTract22.style.boxShadow = `0 0 0 2px ${this.props.handleColor}`;
    }
    if (node && this.props && this.props.vertical === true) {
      if (!this.props.style.height) (node as any).style.height = "200px";
      if (this.props.style?.display !== "block" && node.parentElement && node.parentElement.style) {
        node.parentElement.style.display = "inline-flex";
      }
    }
    if (node) {
      const sliderTract11: HTMLElement = node.getElementsByClassName("ant-slider-handle")[0] as HTMLElement;
      sliderTract11?.setAttribute("focusid", this.props.id);
    }
  }

  onChange = (value: [number, number]) => {
    if (this.props.onChange) {
      this.props.onChange({
        startValue: value[0],
        endValue: value[1]
      });
    }
  };

  getProps = () => {
    let props: any = {};
    if (this.props) {
      props = _.clone(this.props);
    }
    if (props.sliderColor) {
      delete props.sliderColor;
    }
    if (props.handleColor) {
      delete props.handleColor;
    }
    if (props.startValue) {
      delete props.startValue;
    }
    if (props.endValue) {
      delete props.endValue;
    }
    delete props.range;
    props.value = [this.state.startValue, this.state.endValue];
    return props;
  };

  render(): ReactNode {
    return (
      <>
        <AntdSlider {...this.getProps()} range={true} onChange={this.onChange} />
      </>
    );
  }
}

const rangeSlider = withCommonEvents(RangeSlider);
export { rangeSlider as RangeSlider };
