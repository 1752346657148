import { Slider as AntdSlider } from "antd";
import { SliderBaseProps, SliderSingleProps } from "antd/lib/slider";
import _ from "lodash";
import { PureComponent, ReactNode } from "react";
import { findDOMNode } from "react-dom";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common-props";

interface KSliderProps extends SliderBaseProps, SliderSingleProps {
  style: any;
  sliderColor: string;
  handleColor: string;
  railColor: string;
  onChange: any;
  id?: string;
  value?: number;
}

interface KSliderState {}

class Slider extends PureComponent<KSliderProps & CommonProps, KSliderState> {
  constructor(props: KSliderProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const node = findDOMNode(this) as Element;
    if (this.props && this.props.sliderColor) {
      const sliderTract: HTMLElement = node.getElementsByClassName("ant-slider-track")[0] as HTMLElement;
      sliderTract.style.backgroundColor = this.props.sliderColor;
    }
    if (this.props && this.props.railColor) {
      const sliderTract: HTMLElement = node.getElementsByClassName("ant-slider-rail")[0] as HTMLElement;
      sliderTract.style.backgroundColor = this.props.railColor;
    }
    if (this.props && this.props.handleColor) {
      const sliderTract: HTMLElement = node.getElementsByClassName("ant-slider-handle")[0] as HTMLElement;
      sliderTract.style.boxShadow = `0 0 0 2px ${this.props.handleColor}`;
      if (node.getElementsByClassName("ant-slider-handle").length > 1) {
        const sliderTract2: HTMLElement = node.getElementsByClassName("ant-slider-handle")[1] as HTMLElement;
        sliderTract2.style.boxShadow = `0 0 0 2px ${this.props.handleColor}`;
      }
    }
    if (node && this.props && this.props.vertical === true) {
      if (!this.props.style.height) (node as any).style.height = "200px";
      if (node.parentElement && node.parentElement.style) {
        node.parentElement.style.display = "inline-flex";
      }
    }
    if (node && this.props?.style?.height && this.props.vertical === false && node.children?.length > 3) {
      const height = this.getNumbersOfStyleProperties(this.props.style.height);
      (node.children[3] as any).style.top = `${height / 2 - 5}px`;
    }
    if (node && this.props?.style?.width && this.props.vertical === true && node.children?.length > 3) {
      const width = this.getNumbersOfStyleProperties(this.props.style.width);
      (node.children[3] as any).style.left = `${width / 2 - 5}px`;
    }
    if (node) {
      const sliderTract11: HTMLElement = node.getElementsByClassName("ant-slider-handle")[0] as HTMLElement;
      sliderTract11?.setAttribute("focusid", this.props.id);
    }
  }

  getNumbersOfStyleProperties = (val: any) => {
    let numb: any = val?.match(/\d/g);
    numb = numb?.join("");
    return numb;
  };

  getProps = () => {
    let props: any = {};
    if (this.props) {
      props = _.clone(this.props);
    }
    if (props.sliderColor) {
      delete props.sliderColor;
    }
    if (props.handleColor) {
      delete props.handleColor;
    }

    if (props.style) {
      props.style = { ...props.style, display: "flex", alignItems: "center", justifyContent: "center" };
    }
    return props;
  };

  render(): ReactNode {
    return (
      <>
        <AntdSlider {...this.getProps()} range={false} />
      </>
    );
  }
}

const slider = withCommonEvents(Slider);
export { slider as Slider };
