import { Avatar, Button, Dropdown, Input, InputRef } from "antd";
import { MenuProps } from "antd/lib";
import moment from "moment";
import React, { Component, ReactNode } from "react";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { KMainFunctions } from "../../../shared/utilty/main-functions";
import { CommonProps } from "../common-props";
import "./chat.css";

declare let window: any;

interface ChatProps {
  dataSource?: object[];
  onMessageSend?: (message: string) => void;
  onEditMessage?: () => void;
  onDeleteMessage?: () => void;
  chatIdField: string;
  chatMessageField: string;
  chatUserDisplayNameField: string;
  chatUserNameField: string;
  chatUserAvatarField: string;
  chatDateField: string;
  currentUsername: string;
  style?: React.CSSProperties;
  darkMode?: boolean;
  showProfilePhoto?: boolean;
  showUserName?: boolean;
  allowEditMessage?: boolean;
  allowDeleteMessage?: boolean;
}

interface ChatState {
  isFocusedToInput: boolean;
  isEditing: boolean;
  currentlyEditingMessageId: unknown;
  message: string;
}

class Chat extends Component<ChatProps & CommonProps, ChatState> {
  private inputRef = React.createRef<InputRef>();

  private messagesEnd: HTMLDivElement | null | undefined;

  public messageIdToBeDeleted: unknown = null;

  constructor(props: ChatProps) {
    super(props);
    this.state = {
      isFocusedToInput: false,
      isEditing: false,
      currentlyEditingMessageId: null,
      message: ""
    };
  }

  scrollToBottom = () => {
    if (window.kuika?.dashboardState !== 1) {
      this.messagesEnd?.scrollIntoView({ behavior: "smooth" });
    }
  };

  componentDidMount() {
    this.scrollToBottom();
    document.addEventListener("keydown", this.handleKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown);
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  getMessage = () => {
    return this.state.message;
  };

  setMessage = (message: unknown) => {
    if (typeof message === "string") {
      this.setState({ message });
      return;
    }

    if (typeof message === "number") {
      this.setState({ message: message.toString() });
      return;
    }

    if (message !== null && message !== undefined) {
      console.warn("Chat: Invalid message type");
    }

    this.setState({ message: "" });
  };

  onChangeInput = (e) => {
    if (window.kuika?.isDesignTime) return;
    this.setState({ message: e.target.value });
  };

  getCurrentlyEditingMessageId = () => {
    return this.state.currentlyEditingMessageId;
  };

  setCurrentlyEditingMessageId = (id: unknown) => {
    if (id === undefined || id === null || id === "") {
      this.onClickCancelEdit();
      return;
    }

    this.setState({ isEditing: true, currentlyEditingMessageId: id });
  };

  sendMessage = async () => {
    this.props.onMessageSend?.(this.state.message);
    this.setState({ message: "" });
  };

  static getUserLettersToShow = (userName): string => {
    if (!userName) return "";
    let str = "";
    if (userName && userName.length > 0) str += userName.substring(0, 1);
    return str.toUpperCase();
  };

  static addDefaultSrc = (e: any) => {
    // TODO: Bad practice to use src from another origin.
    e.target.src =
      "https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Solid_purple.svg/2048px-Solid_purple.svg.png";
  };

  static formatDateToHourAndDay = (date: string) => {
    if (!date) return null;

    if (moment(date).isSame(moment(), "day")) {
      return moment(date).format("HH:mm");
    }

    if (moment(date).isSame(moment(), "year")) {
      return moment(date).format("DD MMM HH:mm");
    }

    return moment(date).format("DD MMM YYYY HH:mm");
  };

  private getIsCurrentUser = (item): boolean => {
    return (
      (item[this.props.chatUserNameField] ?? item.userId) ===
      (item[this.props.chatUserNameField] ? this.props.currentUsername : "1")
    );
  };

  private handleKeyDown = (e: KeyboardEvent) => {
    if (window.kuika?.isDesignTime) return;

    if (this.state.isFocusedToInput && this.state.isEditing && e.key === "Escape") {
      this.onKeyEscapeKeyDownInEditingMode();
    }
  };

  private onClickCancelEdit = () => {
    this.setState({ isEditing: false, message: "", currentlyEditingMessageId: null });
  };

  private onClickEditMessage = (messageId: unknown) => {
    if (window.kuika?.isDesignTime) return;

    const message = this.props.dataSource?.find((item) => item[this.props.chatIdField] === messageId);

    if (message) {
      this.setState({
        isEditing: true,
        message: message[this.props.chatMessageField],
        currentlyEditingMessageId: messageId
      });
      this.inputRef.current?.focus();
    } else {
      KMainFunctions.displayErrorNotification("Message not found");
    }
  };

  private editMessage = async () => {
    if (
      this.props.onEditMessage &&
      this.state.currentlyEditingMessageId !== undefined &&
      this.state.currentlyEditingMessageId !== null
    ) {
      await this.props.onEditMessage();
      this.setState({ isEditing: false, message: "", currentlyEditingMessageId: null });
    }
  };

  private onClickDeleteMessage = async (messageId: unknown) => {
    if (window.kuika?.isDesignTime) return;

    if (this.props.onDeleteMessage) {
      this.messageIdToBeDeleted = messageId;
      await this.props.onDeleteMessage();
    }
  };

  private onKeyEscapeKeyDownInEditingMode = () => {
    this.onClickCancelEdit();
  };

  private getDropdownItems = (messageId: unknown): MenuProps["items"] => {
    if (this.state.isEditing) {
      return [
        {
          key: "1",
          label: "Cancel editing message",
          danger: true,
          onClick: () => this.onClickCancelEdit()
        }
      ];
    }

    const items: MenuProps["items"] = [];

    if (this.props.allowEditMessage) {
      items.push({
        key: "1",
        label: "Edit message",
        onClick: () => this.onClickEditMessage(messageId)
      });
    }

    if (this.props.allowDeleteMessage) {
      items.push({
        key: "2",
        label: "Delete message",
        danger: true,
        onClick: () => this.onClickDeleteMessage(messageId)
      });
    }

    return items;
  };

  private onSubmit = () => {
    if (this.state.isEditing) {
      this.editMessage();
      return;
    }

    this.sendMessage();
  };

  render(): ReactNode {
    return (
      <div style={{ display: this.props.style?.display === "inline" ? "inline-flex" : "block" }}>
        <div className={this.props.darkMode ? "kuika__dark-chat" : "kuika__chat"} style={this.props.style}>
          <div className="kuika__chat-texts">
            {this.props.dataSource?.map((item: any) => {
              return (
                <div
                  className={this.getIsCurrentUser(item) ? "kuika__chat-text kuika__chat-sent" : "kuika__chat-text"}
                  key={item[this.props.chatIdField] ? item[this.props.chatIdField] : item.id}
                  style={{
                    filter:
                      this.state.isEditing && this.state.currentlyEditingMessageId !== item[this.props.chatIdField]
                        ? "blur(2px)"
                        : "none"
                  }}
                >
                  {this.props.showProfilePhoto === true && (
                    <div className="kuika__chat__profile-pic">
                      {item[this.props.chatUserAvatarField] ? (
                        <img onError={Chat.addDefaultSrc} src={item[this.props.chatUserAvatarField]} alt="" />
                      ) : (
                        <Avatar
                          size={50}
                          style={{
                            color: "#fff",
                            fontWeight: 600,
                            fontSize: "26px",
                            letterSpacing: "0.37px",
                            backgroundColor: "rgb(75 54 243)"
                          }}
                        >
                          {Chat.getUserLettersToShow(
                            item[this.props.chatUserDisplayNameField]
                              ? item[this.props.chatUserDisplayNameField]
                              : item.userName
                          )}
                        </Avatar>
                      )}
                    </div>
                  )}
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
                    <div className="kuika__chat__text-content">
                      {this.props.showUserName === true && (
                        <div>
                          <h5
                            style={{
                              color: this.props.style?.color,
                              fontSize: this.props.style?.fontSize,
                              lineHeight: this.props.style?.lineHeight ?? "130%",
                              letterSpacing: this.props.style?.letterSpacing ?? 0
                            }}
                          >
                            {item[this.props.chatUserDisplayNameField] ?? item.userName}
                          </h5>
                        </div>
                      )}
                      <span
                        style={{
                          color: this.props.style?.color,
                          fontSize: this.props.style?.fontSize,
                          lineHeight: this.props.style?.lineHeight ?? "130%",
                          letterSpacing: this.props.style?.letterSpacing
                        }}
                      >
                        {item[this.props.chatMessageField] ?? item.message}
                      </span>
                      {this.getIsCurrentUser(item) && (
                        <>
                          {this.state.isEditing &&
                            this.state.currentlyEditingMessageId === item[this.props.chatIdField] && (
                              <span className="kuika__chat__editing_indicator">Editing...</span>
                            )}

                          {!this.state.isEditing && (this.props.allowEditMessage || this.props.allowDeleteMessage) && (
                            <Dropdown
                              menu={{ items: this.getDropdownItems(item[this.props.chatIdField]) }}
                              placement="bottomRight"
                              trigger={["click"]}
                            >
                              <button className="kuika__chat__dots">
                                <svg
                                  width="15"
                                  height="15"
                                  viewBox="0 0 15 15"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.625 2.5C8.625 3.12132 8.12132 3.625 7.5 3.625C6.87868 3.625 6.375 3.12132 6.375 2.5C6.375 1.87868 6.87868 1.375 7.5 1.375C8.12132 1.375 8.625 1.87868 8.625 2.5ZM8.625 7.5C8.625 8.12132 8.12132 8.625 7.5 8.625C6.87868 8.625 6.375 8.12132 6.375 7.5C6.375 6.87868 6.87868 6.375 7.5 6.375C8.12132 6.375 8.625 6.87868 8.625 7.5ZM7.5 13.625C8.12132 13.625 8.625 13.1213 8.625 12.5C8.625 11.8787 8.12132 11.375 7.5 11.375C6.87868 11.375 6.375 11.8787 6.375 12.5C6.375 13.1213 6.87868 13.625 7.5 13.625Z"
                                    fill="currentColor"
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </button>
                            </Dropdown>
                          )}
                        </>
                      )}
                    </div>
                    <span style={{ color: "gray", fontSize: 10 }}>
                      {Chat.formatDateToHourAndDay(item[this.props.chatDateField])}
                    </span>
                  </div>
                  <div
                    ref={(el) => {
                      this.messagesEnd = el;
                    }}
                  ></div>
                </div>
              );
            })}
          </div>
          <div className="kuika__chat__send-message">
            {this.state.isEditing && (
              <Button className="kuika__chat__button-send" onClick={this.onClickCancelEdit}>
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={
                    this.props.darkMode
                      ? {
                          filter:
                            "invert(100%) sepia(0%) saturate(7500%) hue-rotate(345deg) brightness(103%) contrast(92%)"
                        }
                      : {
                          filter:
                            "invert(0%) sepia(90%) saturate(7460%) hue-rotate(90deg) brightness(91%) contrast(93%)"
                        }
                  }
                >
                  <path
                    d="M12.8536 2.85355C13.0488 2.65829 13.0488 2.34171 12.8536 2.14645C12.6583 1.95118 12.3417 1.95118 12.1464 2.14645L7.5 6.79289L2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L6.79289 7.5L2.14645 12.1464C1.95118 12.3417 1.95118 12.6583 2.14645 12.8536C2.34171 13.0488 2.65829 13.0488 2.85355 12.8536L7.5 8.20711L12.1464 12.8536C12.3417 13.0488 12.6583 13.0488 12.8536 12.8536C13.0488 12.6583 13.0488 12.3417 12.8536 12.1464L8.20711 7.5L12.8536 2.85355Z"
                    fill="#000000"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </Button>
            )}
            <div
              className={
                this.props.darkMode
                  ? "kuika__dark-chat__message-text kuika__dark-chat__input"
                  : "kuika__chat__message-text"
              }
            >
              <Input
                ref={this.inputRef}
                onPressEnter={this.onSubmit}
                className="kuika__chat__text-input"
                onChange={(e) => this.onChangeInput(e)}
                onFocus={() => this.setState({ isFocusedToInput: true })}
                onBlur={() => this.setState({ isFocusedToInput: false })}
                value={this.state.message}
                type="text"
                placeholder="Send Message"
              />
            </div>
            <Button className="kuika__chat__button-send" onClick={this.onSubmit}>
              {this.state.isEditing ? (
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11.4669 3.72684C11.7558 3.91574 11.8369 4.30308 11.648 4.59198L7.39799 11.092C7.29783 11.2452 7.13556 11.3467 6.95402 11.3699C6.77247 11.3931 6.58989 11.3355 6.45446 11.2124L3.70446 8.71241C3.44905 8.48022 3.43023 8.08494 3.66242 7.82953C3.89461 7.57412 4.28989 7.55529 4.5453 7.78749L6.75292 9.79441L10.6018 3.90792C10.7907 3.61902 11.178 3.53795 11.4669 3.72684Z"
                    fill="#000000"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              ) : (
                <svg
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  style={
                    this.props.darkMode
                      ? {
                          filter:
                            "invert(100%) sepia(0%) saturate(7500%) hue-rotate(345deg) brightness(103%) contrast(92%)"
                        }
                      : {
                          filter:
                            "invert(0%) sepia(90%) saturate(7460%) hue-rotate(90deg) brightness(91%) contrast(93%)"
                        }
                  }
                >
                  <g>
                    <path d="M21.13 11.358L3.614 2.108c-.29-.152-.64-.102-.873.126-.23.226-.293.577-.15.868l4.362 8.92-4.362 8.92c-.143.292-.08.643.15.868.145.14.333.212.523.212.12 0 .24-.028.35-.087l17.517-9.25c.245-.13.4-.386.4-.664s-.155-.532-.4-.662zM4.948 4.51l12.804 6.762H8.255l-3.307-6.76zm3.307 8.26h9.498L4.948 19.535l3.307-6.763z"></path>
                  </g>
                </svg>
              )}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const chat = withCommonEvents(Chat);
export { chat as Chat };
