import { Steps } from "antd";
import { Guid } from "guid-typescript";
import { isEmpty } from "lodash";
import { CSSProperties, PureComponent } from "react";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common/common-props";

declare let window: Window & { kuika: any };

interface IStepperProps {
  activeKey: string;
  defaultActiveKey: string;
  onChange?: (activeKey: string) => any;
  value?: string | number;
  children?: any;
  style?: CSSProperties;
  stepDirection: "horizontal" | "vertical";
  unfinishedStepColor: string;
  finishedStepColor: string;
  activeStepColor: string;
}

interface IStepperState {
  uniqueKey: Guid;
}

class Stepper extends PureComponent<IStepperProps & CommonProps, IStepperState> {
  private memoizedDynamicCssResult = "";

  constructor(props: IStepperProps) {
    super(props);
    this.state = {
      uniqueKey: Guid.create()
    };
  }

  componentDidMount() {
    this.setDynamicStyle();
  }

  componentDidUpdate(
    prevProps: Readonly<IStepperProps & CommonProps>,
    prevState: Readonly<IStepperState>,
    snapshot?: any
  ): void {
    if (window.kuika?.isDesignTime) return;

    if (this.props.value !== prevProps.value && this.props.onChange) {
      this.props.onChange(`${this.props.value}`);
    }
  }

  getClassName = () => {
    return `kuikaStepperComponent_${this.state.uniqueKey.toString().substring(0, 8)}`;
  };

  getStyle = (): CSSProperties => {
    const { style: styleProp, stepDirection } = { ...this.props };
    const style = { ...styleProp };

    if (stepDirection === "vertical") {
      style.display = "flex";
    }

    return style;
  };

  setDynamicStyle = () => {
    const uniquekey = this.state.uniqueKey?.toString();
    if (!uniquekey) {
      return;
    }
    const isDesignTime = window.kuika?.isDesignTime;
    if (this.memoizedDynamicCssResult !== "" && !isDesignTime) {
      return this.memoizedDynamicCssResult;
    }
    const dynamic_style = document.getElementById("dynamic_style");
    if (dynamic_style && dynamic_style.innerHTML?.indexOf(uniquekey) === -1) {
      const generatedCss = this.getDynamicCss();
      dynamic_style.innerHTML = `${dynamic_style.innerHTML} 
        ${generatedCss}`;
      this.memoizedDynamicCssResult = generatedCss;
    }
  };

  getDynamicCss = () => {
    const className: string = this.getClassName();
    const { activeStepColor, finishedStepColor, unfinishedStepColor } = this.props;

    if (!className) return "";
    let result = `.${className.trim()} .ant-steps-vertical > .ant-steps-item {
      flex: none;
    }`;

    result += `.${className.trim()} .ant-steps + * {
      flex: 1 1;
    }`;

    result += `.${className.trim()} .ant-steps-vertical {
      width: auto;
      display: inline-flex;
    }`;

    result += `.${className.trim()} .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-tail::after {
      background-color: ${unfinishedStepColor};
    }`;

    result += `.${className.trim()} .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
      background-color: ${activeStepColor};
    }`;

    result += `.${className.trim()} .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)>.ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
      border-color: ${activeStepColor}; 
    }`;

    result += `.${className.trim()} .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)>.ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
      color: ${activeStepColor}; 
    }`;

    result += `.${className.trim()} .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button']:hover .ant-steps-item-title {
      color: ${activeStepColor};
    }`;

    result += `.${className.trim()} .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
      color: ${activeStepColor};
    }`;

    result += `.${className.trim()} .ant-steps-item-wait .ant-steps-item-icon {
      background-color: ${unfinishedStepColor};
      border-color: ${unfinishedStepColor};
    }`;

    result += `.${className.trim()} .ant-steps-item-finish .ant-steps-item-icon {
      background-color: ${finishedStepColor};
      border-color: ${finishedStepColor};
    }`;

    result += `.${className.trim()} .ant-steps-item-process .ant-steps-item-icon {
      background-color: ${activeStepColor};
      border-color: ${activeStepColor};
    }`;

    return result;
  };

  getItems = () => {
    const { children } = this.props;
    if (!children) return [];

    if (!children.length) {
      if (children.key)
        return [children]
          .filter((item) => item.props.visibility !== "hidden")
          .map((item) => ({ key: item.props.title, title: item.props.title, content: item }));

      return [];
    }

    return children
      .filter((item) => item.props.visibility !== "hidden")
      .map((item) => ({ key: item.props.title, title: item.props.title, content: item }));
  };

  handleChangeStepOnDesignTime = (index) => {
    if (!window.kuika?.isDesignTime) return;

    const activeStepKey = this.props.children[index].key;

    if (this.props.onChange) {
      this.props.onChange(activeStepKey);
    }
  };

  setStep = (screen, activeStepKey) => {
    // if (this.props.onChange) {
    //   this.props.onChange(activeStepKey);
    // }
  };

  getIndex = (steps = this.getItems()) => {
    if (!steps.length) return undefined;

    let activeKey = this.props.value;
    let idProperty = "id";

    if (window.kuika?.isDesignTime) {
      idProperty = "kuikaid";
      activeKey = this.props.activeKey;
    }

    if (isEmpty(activeKey)) {
      return 0;
    }

    if (!activeKey) return 0;
    const index = steps.findIndex((step) => step.content.props[idProperty] === activeKey);

    return index < 0 ? 0 : index;
  };

  render() {
    const steps = this.getItems();
    const index = this.getIndex(steps);

    return (
      <div className={this.getClassName()} style={this.getStyle()}>
        <Steps
          current={index}
          onChange={this.handleChangeStepOnDesignTime}
          items={steps}
          direction={this.props.stepDirection}
        />
        {steps[index].content}
      </div>
    );
  }
}

const stepper = withCommonEvents(Stepper);
export { stepper as Stepper };
