import { CSSProperties, PureComponent, ReactNode, createRef } from "react";
import isEqual from "react-fast-compare";
import Board from "react-trello";

import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common-props";

import { Button, Dropdown, Form, FormInstance, Input } from "antd";
import { MenuProps } from "antd/lib";
import "../../../styles/components/kanban-board.scss";

declare let window: Window & { [key: string]: any };

interface IKanbanLane {
  id: string;
  title?: string;
  label?: string;
  droppable?: string;
  index?: number;
  targetIndex?: number;
  laneIndex?: any;
  cards: IKanbanCard[];
}

interface IKanbanCard {
  id: string;
  laneId?: string;
  title?: string;
  label?: string;
  subTitle?: string;
  description?: string;
  escalationText?: string;
  draggable?: boolean | null;
  index?: number;
  cardIndex?: number;
  sortValue?: any;
  targetIndex?: number;
  targetLaneId?: string;
}

export interface IKanbanBoardProps {
  laneOptions?: any[];
  cardOptions?: any[];
  editable?: boolean;
  isCardsDeletable?: boolean;
  isLanesDeletable?: boolean;
  isCardsDraggable?: boolean;
  isLanesDraggable?: boolean;
  isCardsEditable?: boolean;
  isLanesEditable?: boolean;
  collapsibleLanes?: boolean;
  editLaneTitle?: boolean;
  canAddLanes?: boolean;
  laneIdField?: string;
  laneTitleField?: string;
  laneLabelField?: string;
  laneDroppableField?: string;
  laneIndexField?: string;
  cardIdField?: string;
  cardLaneIdField?: string;
  cardTitleField?: string;
  cardSubTitleField?: string;
  cardLabelField?: string;
  cardDescriptionField?: string;
  cardEscalationTextField?: string;
  cardDraggableField?: string;
  cardIndexField?: string;
  cardSortKeyField?: string;
  onLaneAdd?: () => void;
  onLaneEdit?: () => void;
  onLaneDelete?: () => void;
  onLaneDragged?: () => void;
  onCardAdd?: () => void;
  onCardEdit?: () => void;
  onCardDelete?: () => void;
  onCardDragged?: () => void;
  style?: CSSProperties;
}
export interface IKanbanBoardState {
  editingLane?: IKanbanLane;
  editingCard?: IKanbanCard;
  kanbanBoardData: { lanes: IKanbanLane[] };
  eventBus?: any;
}

class KanbanBoard extends PureComponent<IKanbanBoardProps & CommonProps, IKanbanBoardState> {
  private static readonly dashboardLaneIdFieldKey = "id";

  private static readonly dashboardLaneTitleFieldKey = "title";

  private static readonly dashboardLaneLabelFieldKey = "label";

  private static readonly dashboardLaneIndexFieldKey = "index";

  private static readonly dashboardLaneDroppableFieldKey = "droppable";

  private static readonly dashboardCardIdFieldKey = "id";

  private static readonly dashboardCardLaneIdFieldKey = "laneId";

  private static readonly dashboardCardTitleFieldKey = "title";

  private static readonly dashboardCardSubTitleFieldKey = "subTitle";

  private static readonly dashboardCardLabelFieldKey = "label";

  private static readonly dashboardCardDescriptionFieldKey = "description";

  private static readonly dashboardCardEscalationTextFieldKey = "escalationText";

  private static readonly dashboardCardDraggableFieldKey = "draggable";

  private static readonly dashboardCardIndexFieldKey = "index";

  private static readonly dashboardCardSortKeyField = "index";

  private static dashboardLanesData: IKanbanLane[] = [
    {
      id: "lane",
      title: "Lane Title",
      label: "Lane Label",
      cards: []
    }
  ];

  private static readonly dashboardCardsData: IKanbanCard[] = [
    {
      id: "card",
      laneId: "lane",
      title: "Card",
      subTitle: "Card Subtitle",
      description: "Card Description",
      label: "Card Label",
      escalationText: "Card Escalation Text"
    }
  ];

  private readonly isDesignTime: boolean;

  private laneIdFieldKey?: string;

  private laneTitleFieldKey?: string;

  private laneLabelFieldKey?: string;

  private laneDroppableFieldKey?: string;

  private laneIndexFieldKey?: string;

  private cardIdFieldKey?: string;

  private cardLaneIdFieldKey?: string;

  private cardTitleFieldKey?: string;

  private cardSubTitleFieldKey?: string;

  private cardLabelFieldKey?: string;

  private cardDescriptionFieldKey?: string;

  private cardEscalationTextFieldKey?: string;

  private cardDraggableFieldKey?: string;

  private cardIndexFieldKey?: string;

  private cardSortFieldKey?: string;

  private cardFormRef = createRef<FormInstance>();

  private laneFormRef = createRef<FormInstance>();

  private laneData: IKanbanLane | null = null;

  private cardData: IKanbanCard | null = null;

  constructor(props: IKanbanBoardProps) {
    super(props);
    this.state = {
      eventBus: undefined,
      kanbanBoardData: { lanes: [] }
    };

    this.isDesignTime = window.kuika?.isDesignTime;
    this.initializeFieldKeys(props);
  }

  componentDidMount(): void {
    if (this.isDesignTime) this.setKanbanBoardData();
  }

  componentDidUpdate(
    prevProps: Readonly<IKanbanBoardProps & CommonProps>,
    prevState: Readonly<IKanbanBoardState>,
    snapshot?: any
  ): void {
    if (
      !isEqual(prevProps.cardOptions, this.props.cardOptions) ||
      !isEqual(prevProps.laneOptions, this.props.laneOptions)
    ) {
      this.setKanbanBoardData();
    }
  }

  private initializeFieldKeys = (props: IKanbanBoardProps) => {
    if (this.isDesignTime) {
      this.laneIdFieldKey = KanbanBoard.dashboardLaneIdFieldKey;
      this.laneTitleFieldKey = KanbanBoard.dashboardLaneTitleFieldKey;
      this.laneLabelFieldKey = KanbanBoard.dashboardLaneLabelFieldKey;
      this.laneDroppableFieldKey = KanbanBoard.dashboardLaneDroppableFieldKey;
      this.laneIndexFieldKey = KanbanBoard.dashboardLaneIndexFieldKey;
      this.cardIdFieldKey = KanbanBoard.dashboardCardIdFieldKey;
      this.cardLaneIdFieldKey = KanbanBoard.dashboardCardLaneIdFieldKey;
      this.cardTitleFieldKey = KanbanBoard.dashboardCardTitleFieldKey;
      this.cardSubTitleFieldKey = KanbanBoard.dashboardCardSubTitleFieldKey;
      this.cardLabelFieldKey = KanbanBoard.dashboardCardLabelFieldKey;
      this.cardDescriptionFieldKey = KanbanBoard.dashboardCardDescriptionFieldKey;
      this.cardEscalationTextFieldKey = KanbanBoard.dashboardCardEscalationTextFieldKey;
      this.cardDraggableFieldKey = KanbanBoard.dashboardCardDraggableFieldKey;
      this.cardIndexFieldKey = KanbanBoard.dashboardCardIndexFieldKey;
      return;
    }

    this.laneIdFieldKey = props.laneIdField;
    this.laneTitleFieldKey = props.laneTitleField;
    this.laneLabelFieldKey = props.laneLabelField;
    this.laneDroppableFieldKey = props.laneDroppableField;
    this.laneIndexFieldKey = props.laneIndexField;
    this.cardIdFieldKey = props.cardIdField;
    this.cardLaneIdFieldKey = props.cardLaneIdField;
    this.cardTitleFieldKey = props.cardTitleField;
    this.cardSubTitleFieldKey = props.cardSubTitleField;
    this.cardLabelFieldKey = props.cardLabelField;
    this.cardDescriptionFieldKey = props.cardDescriptionField;
    this.cardEscalationTextFieldKey = props.cardEscalationTextField;
    this.cardDraggableFieldKey = props.cardDraggableField;
    this.cardIndexFieldKey = props.cardIndexField;
    this.cardSortFieldKey = props.cardSortKeyField;
  };

  private setKanbanBoardData = () => {
    const { cardOptions, laneOptions, isCardsDraggable } = this.props;
    const lanes: any[] = this.isDesignTime ? KanbanBoard.dashboardLanesData : laneOptions ?? [];
    const cards: any[] = this.isDesignTime ? KanbanBoard.dashboardCardsData : cardOptions ?? [];

    this.setState({
      kanbanBoardData: {
        lanes: lanes
          .map((lane) => {
            const mappedLane: IKanbanLane = {
              id: lane[this.laneIdFieldKey],
              title: lane[this.laneTitleFieldKey],
              label: lane[this.laneLabelFieldKey],
              droppable:
                typeof lane[this.laneDroppableFieldKey] === "boolean" ? lane[this.laneDroppableFieldKey] : undefined,
              laneIndex: lane[this.laneIndexFieldKey],
              cards: []
            };

            const laneCards = cards.reduce((filtered, cardData) => {
              if (mappedLane.id == cardData[this.cardLaneIdFieldKey]) {
                const modifiedCardData: IKanbanCard = {
                  id: cardData[this.cardIdFieldKey],
                  title: cardData[this.cardTitleFieldKey],
                  subTitle: cardData[this.cardSubTitleFieldKey],
                  label: cardData[this.cardLabelFieldKey],
                  description: cardData[this.cardDescriptionFieldKey],
                  escalationText: cardData[this.cardEscalationTextFieldKey],
                  draggable:
                    typeof cardData[this.cardDraggableFieldKey] === "boolean"
                      ? cardData[this.cardDraggableFieldKey]
                      : isCardsDraggable,
                  cardIndex: cardData[this.cardIndexFieldKey],
                  sortValue: this.cardSortFieldKey ? cardData[this.cardSortFieldKey] : cardData[this.cardIndexFieldKey]
                };
                filtered.push(modifiedCardData);
              }
              return filtered;
            }, []);
            mappedLane.cards = laneCards.sort((card1: IKanbanCard, card2: IKanbanCard) => {
              if (card1.sortValue < card2.sortValue) return -1;
              if (card2.sortValue < card1.sortValue) return 1;
              return 0;
            });

            return mappedLane;
          })
          .sort((lane1, lane2) => {
            if (lane1.laneIndex < lane2.laneIndex) {
              return -1;
            }

            if (lane2.laneIndex < lane1.laneIndex) {
              return 1;
            }

            return 0;
          })
      }
    });
  };

  private getBoardProps = () => {
    const {
      canAddLanes,
      editable,
      isLanesEditable,
      isCardsEditable,
      isLanesDraggable,
      isCardsDraggable,
      isLanesDeletable,
      isCardsDeletable,
      collapsibleLanes,
      style
    } = this.props;
    const props: any = {};

    props.style = { ...style, overflow: "auto", font: "14px/18px 'Helvetica Neue', Arial, Helvetica, sans-serif" };

    if (this.isDesignTime) {
      props.editable = false;
      props.cardDraggable = false;
      props.laneDraggable = false;
      props.editLaneTitle = false;
      props.canAddLanes = false;
      props.isCardsDeletable = false;
    } else {
      props.canAddLanes = canAddLanes;
      props.editable = editable;
      props.isLanesEditable = isLanesEditable;
      props.isCardsEditable = isCardsEditable;
      props.isLanesDraggable = isLanesDraggable;
      props.isCardsDraggable = isCardsDraggable;
      props.isLanesDeletable = isLanesDeletable;
      props.isCardsDeletable = isCardsDeletable;
      props.collapsibleLanes = collapsibleLanes;
      props.laneDragClass = "draggingLane";
      props.cardDraggalbe = props.isCardsDraggable;
      props.laneDraggable = props.isLanesDraggable;
      props.draggable = true;
    }

    delete props.isCardsDeletable;

    return props;
  };

  private setEventBus = (handle) => {
    this.setState({ eventBus: handle });
  };

  private toggleEditingLaneAndCardData = ({
    editingCard,
    editingLane
  }: {
    editingCard?: IKanbanCard;
    editingLane?: IKanbanLane;
  } = {}) => {
    this.setState({
      editingCard,
      editingLane
    });
  };

  private clearCardData = () => {
    this.toggleEditingLaneAndCardData();
  };

  private onClickEditCard = (editingCard: IKanbanCard) => {
    this.toggleEditingLaneAndCardData({ editingCard });
  };

  private onClickDeleteCard = (card: IKanbanCard) => {
    this.cardData = card;
    this.laneData = null;
    if (this.props.onCardDelete) {
      this.props.onCardDelete();
    }
  };

  private clearLaneData = () => {
    this.toggleEditingLaneAndCardData();
  };

  private onClickEditLane = (editingLane: IKanbanLane) => {
    this.toggleEditingLaneAndCardData({ editingLane });
  };

  private onClickDeleteLane = (lane: IKanbanLane) => {
    this.laneData = lane;
    this.cardData = null;
    if (this.props.onLaneDelete) {
      this.props.onLaneDelete();
    }
  };

  private getCardDropdownItems = (card: IKanbanCard): MenuProps["items"] => {
    const items: MenuProps["items"] = [];

    if (this.props.isCardsEditable) {
      items.push({
        key: "1",
        label: "Edit card",
        onClick: () => this.onClickEditCard(card)
      });
    }

    if (this.props.isCardsDeletable) {
      items.push({
        key: "2",
        label: "Delete card",
        danger: true,
        onClick: () => this.onClickDeleteCard(card)
      });
    }

    return items;
  };

  private getLaneDropdownItems = (lane: IKanbanLane): MenuProps["items"] => {
    const items: MenuProps["items"] = [];

    if (this.props.isLanesEditable) {
      items.push({
        key: "1",
        label: "Edit lane",
        onClick: () => this.onClickEditLane(lane)
      });
    }

    if (this.props.isLanesDeletable) {
      items.push({
        key: "2",
        label: "Delete lane",
        danger: true,
        onClick: () => this.onClickDeleteLane(lane)
      });
    }

    return items;
  };

  private cardRenderer = ({ id, cardIndex, laneId, title, subTitle, label, description, escalationText, onClick }) => {
    if (this.state.editingCard?.id === id)
      return this.cardFormRenderer({ id, cardIndex, laneId, title, subTitle, label, description, escalationText });
    return (
      <div className={`kuika__kanban-card`} onClick={onClick}>
        <header className="kuika__kanban-card__header">
          <div className="kuika__kanban-card__title">{title}</div>
          <div className="kuika__kanban-card__label">{label}</div>
          {!this.isDesignTime && (this.props.isCardsEditable || this.props.isCardsDeletable) && (
            <div className="kuika__kanban-card__options-btn-wrapper">
              <Dropdown
                placement="bottomRight"
                menu={{
                  items: this.getCardDropdownItems({
                    id,
                    cardIndex,
                    laneId,
                    title,
                    subTitle,
                    label,
                    description,
                    escalationText
                  })
                }}
              >
                <svg
                  className="kuika__kanban-card__options-btn"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 23 23"
                >
                  <g fill="none" fill-rule="evenodd">
                    <path d="M0 0H23V23H0z"></path>
                    <path
                      fill="#666666"
                      fill-rule="nonzero"
                      d="M6 10c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2zm6 0c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2zm6 0c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2z"
                    ></path>
                  </g>
                </svg>
              </Dropdown>
            </div>
          )}
        </header>
        {(subTitle || description || escalationText) && (
          <div className="kuika__kanban-card__body">
            {subTitle && <div className="kuika__kanban-card__subtitle">{subTitle}</div>}
            {description && <div className="kuika__kanban-card__description">{description}</div>}
            {escalationText && <div className="kuika__kanban-card__escalation-text">{escalationText}</div>}
          </div>
        )}
      </div>
    );
  };

  private cardFormRenderer = (props?: any) => {
    return (
      <Form
        ref={this.cardFormRef}
        className="kuika__kanban-card"
        initialValues={{
          title: props?.title,
          label: props?.label,
          subTitle: props?.subTitle,
          description: props?.description,
          escalationText: props?.escalationText
        }}
        onFinish={(data) => {
          this.cardData = { ...this.state.editingCard, ...data, laneId: props.laneId };
          this.laneData = null;
          if (this.state.editingCard && this.props.onCardEdit) {
            this.props.onCardEdit();
          } else if (this.props.onCardAdd) {
            const cardIndex =
              this.state.kanbanBoardData.lanes.find((lane) => lane.id === props.laneId)?.cards.length ?? 0;

            this.cardData.cardIndex = cardIndex;
            this.props.onCardAdd();
            props.onCancel ? props.onCancel() : () => {};
          }
          this.clearCardData();
        }}
      >
        <header className="kuika__kanban-card__header">
          <Form.Item name="title">
            <Input className="kuika__kanban-card__title" placeholder="Title" />
          </Form.Item>
          <Form.Item name="label">
            <Input className="kuika__kanban-card__label" placeholder="Label" />
          </Form.Item>
        </header>
        <div className="kuika__kanban-card__body">
          <Form.Item name="subTitle">
            <Input className="kuika__kanban-card__subtitle" placeholder="Sub title" />
          </Form.Item>
          <Form.Item name="description">
            <Input.TextArea
              className="kuika__kanban-card__description"
              placeholder="Description"
              autoSize={{ minRows: 3, maxRows: 3 }}
            ></Input.TextArea>
          </Form.Item>
          <Form.Item name="escalationText">
            <Input.TextArea
              className="kuika__kanban-card__escalation-text"
              placeholder="Escalation text"
              autoSize={{ minRows: 3, maxRows: 3 }}
            ></Input.TextArea>
          </Form.Item>
        </div>
        <div className="kuika__kanban-card__form-buttons-container">
          {!props?.onCancel ? (
            <>
              <Button
                type="primary"
                onClick={() => {
                  this.cardFormRef.current?.submit();
                }}
              >
                Save
              </Button>
              <Button danger onClick={this.clearCardData}>
                Cancel
              </Button>
            </>
          ) : (
            <>
              <Button
                type="primary"
                onClick={() => {
                  this.cardFormRef.current?.submit();
                }}
              >
                Add
              </Button>
              <Button
                danger
                onClick={() => {
                  props.onCancel();
                  this.clearCardData();
                }}
              >
                Cancel
              </Button>
            </>
          )}
        </div>
      </Form>
    );
  };

  private laneHeaderRenderer = ({ id, laneIndex, title, label, cards, droppable, className }) => {
    if (this.state.editingLane?.id === id)
      return this.laneFormRenderer({ id, laneIndex, title, label, cards, droppable });

    return (
      <header className={`kuika__kanban-lane-header ${className}`}>
        <div className="kuika__kanban-lane-header__title">{title}</div>
        <div className="kuika__kanban-lane-header__label">{label}</div>
        <div className="kuika__kanban-lane-header__options-btn-wrapper">
          {!this.isDesignTime && (this.props.isLanesEditable || this.props.isLanesDeletable) && (
            <Dropdown
              placement="bottomRight"
              menu={{
                items: this.getLaneDropdownItems({
                  id,
                  laneIndex,
                  title,
                  label,
                  cards,
                  droppable
                })
              }}
            >
              <svg
                className="kuika__kanban-lane-header__options-btn"
                xmlns="http://www.w3.org/2000/svg"
                width="19"
                height="19"
                viewBox="0 0 23 23"
              >
                <g fill="none" fill-rule="evenodd">
                  <path d="M0 0H23V23H0z"></path>
                  <path
                    fill="#666666"
                    fill-rule="nonzero"
                    d="M6 10c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2zm6 0c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2zm6 0c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2z"
                  ></path>
                </g>
              </svg>
            </Dropdown>
          )}
        </div>
      </header>
    );
  };

  private laneFormRenderer = (props?: any) => {
    return (
      <Form
        className={`kuika__kanban-lane-header__form-container ${props.onCancel ? "add-lane-form" : ""}`}
        ref={this.laneFormRef}
        initialValues={{
          title: props?.title,
          label: props?.label
        }}
        onFinish={(data) => {
          this.laneData = { ...this.state.editingLane, ...data };
          this.cardData = null;

          if (this.state.editingLane && this.props.onLaneEdit) {
            this.props.onLaneEdit();
          } else if (this.props.onLaneAdd) {
            const laneIndex = this.state.kanbanBoardData.lanes.length ?? 0;
            this.laneData.laneIndex = laneIndex;
            this.props.onLaneAdd();
            props.onCancel ? props.onCancel() : () => {};
          }
          this.clearLaneData();
        }}
      >
        <header className="kuika__kanban-lane-header">
          <Form.Item name="title">
            <Input className="kuika__kanban-lane-header__title" placeholder="Title" />
          </Form.Item>
          <Form.Item name="label">
            <Input className="kuika__kanban-lane-header__label" placeholder="Label" />
          </Form.Item>
        </header>
        <div className="kuika__kanban-lane-header__form-buttons-container">
          {!props?.onCancel ? (
            <>
              <Button
                type="primary"
                onClick={() => {
                  this.laneFormRef.current?.submit();
                }}
              >
                Save
              </Button>
              <Button onClick={this.clearLaneData}>Cancel</Button>
            </>
          ) : (
            <>
              <Button
                type="primary"
                onClick={() => {
                  this.laneFormRef.current?.submit();
                }}
              >
                Add
              </Button>
              <Button
                onClick={() => {
                  props.onCancel();
                  this.clearLaneData();
                }}
              >
                Cancel
              </Button>
            </>
          )}
        </div>
      </Form>
    );
  };

  private onLaneDragged = (laneData: IKanbanLane) => {
    this.laneData = laneData;
    this.cardData = null;
    if (this.props.onLaneDragged) this.props.onLaneDragged();
  };

  private onCardDragged = (cardData: IKanbanCard) => {
    if (cardData.laneId === cardData.targetLaneId && cardData.cardIndex === cardData.targetIndex) return;

    this.cardData = cardData;
    this.laneData = null;
    if (this.props.onCardDragged) this.props.onCardDragged();
  };

  public getLaneId = () => this.laneData?.id;

  public getLaneTitle = () => this.laneData?.title;

  public getLaneLabel = () => this.laneData?.label;

  public getLaneDroppable = () => this.laneData?.droppable;

  public getLaneIndex = () => this.laneData?.laneIndex;

  public getLaneTargetIndex = () => this.laneData?.targetIndex;

  public getCardId = () => this.cardData?.id;

  public getCardLaneId = () => this.cardData?.laneId;

  public getCardTitle = () => this.cardData?.title;

  public getCardLabel = () => this.cardData?.label;

  public getCardSubTitle = () => this.cardData?.subTitle;

  public getCardDescription = () => this.cardData?.description;

  public getCardEscalationText = () => this.cardData?.escalationText;

  public getCardDraggable = () => this.cardData?.draggable;

  public getCardIndex = () => this.cardData?.cardIndex;

  public getCardTargetIndex = () => this.cardData?.targetIndex;

  public getCardTargetLaneId = () => this.cardData?.targetLaneId;

  render(): ReactNode {
    return (
      <Board
        {...this.getBoardProps()}
        data={this.state.kanbanBoardData}
        handleDragEnd={(_cardId, sourceLaneId, targetLaneId, position, cardDetails) => {
          this.onCardDragged({ ...cardDetails, laneId: sourceLaneId, targetLaneId, targetIndex: position });
          return false;
        }}
        handleLaneDragEnd={(_removedIndex, addedIndex, payload) => {
          this.state.eventBus.publish({ type: "REFRESH_BOARD", data: this.state.kanbanBoardData });
          this.onLaneDragged({ ...payload, targetIndex: addedIndex });
        }}
        components={{
          Card: this.cardRenderer,
          LaneHeader: this.laneHeaderRenderer,
          NewCardForm: this.cardFormRenderer,
          NewLaneForm: this.laneFormRenderer
        }}
        eventBusHandle={this.setEventBus}
      />
    );
  }
}

const kanbanBoard = withCommonEvents(KanbanBoard);
export { kanbanBoard as KanbanBoard };
