import { Statistic, StatisticProps } from "antd";
import { countdownValueType } from "antd/lib/statistic/utils";
import _ from "lodash";
import { PureComponent, ReactNode } from "react";
import withCommonEvents from "../../../shared/hoc/with-common-events";
import { CommonProps } from "../common-props";

interface CountDownProps extends StatisticProps {
  value?: number;
  onFinish?: any;
  onChange?: any;
}

interface CountDownState {
  value: number | undefined;
  isFinished: boolean;
}

class CountDown extends PureComponent<CountDownProps & CommonProps, CountDownState> {
  currentValue: countdownValueType | undefined;

  intervalId: NodeJS.Timeout | null = null;

  constructor(props: CountDownProps) {
    super(props);
    this.state = {
      value: props.value,
      isFinished: false
    };
  }

  getProps = () => {
    const props: any = _.clone(this.props);
    if (props.value) {
      delete props.value;
    }
    if (props.onChange) {
      delete props.onChange;
    }
    if (props.onFinish) {
      delete props.onFinish;
    }
    delete props.style;
    return props;
  };

  static getDerivedStateFromProps(nextProps: CountDownProps, prevState: CountDownState) {
    if (nextProps.value !== prevState.value) {
      return { value: nextProps.value, isFinished: false };
    }
    return null;
  }

  componentDidMount() {
    this.intervalId = setInterval(this.onChange, 1000);
  }

  componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  onFinish = () => {
    this.setState({ isFinished: true, value: 0 });
    if (this.props.onFinish) {
      this.props.onFinish();
    }
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  };

  onChange = () => {
    const { value } = this.state;
    if (value && value > 0) {
      const newValue = value - 1;
      this.setState({ value: newValue });

      if (this.props.onChange) {
        this.props.onChange(newValue);
      }

      if (newValue === 0) {
        this.onFinish();
      }
    }
  };

  getValue = () => {
    const { value } = this.state;
    const seconds = value;
    if (this.state.isFinished) {
      return Date.now();
    }
    return Date.now() + 1000 * seconds!;
  };

  render(): ReactNode {
    const { style } = this.props;
    return (
      <>
        <Statistic.Countdown
          {...this.getProps()}
          valueStyle={style}
          value={this.getValue()}
          onChange={(e) => (this.currentValue = e)}
          onFinish={this.onFinish}
        />
      </>
    );
  }
}

const countDown = withCommonEvents(CountDown);
export { countDown as CountDown };
